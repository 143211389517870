import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { fixedCamerasSaga } from './saga';

const initialState = {
  cameras: [],
  selectedCamera: null,
  locations: null,
  loading: false,
  backendValidationError: '',
  roadTypes: [],
  permission: 'copSettingsRead'
};

const slice = createSlice({
  name: 'fixedCameras',
  initialState,
  reducers: {
    fetchCameras: () => {},
    storeCameras: (state, action) => {
      state.cameras = action.payload?.cameras;
      state.permission = action.payload?.permission;
    },
    fetchLocations: () => {},
    storeLocations: (state, action) => {
      state.locations = action.payload?.locations;
    },
    fetchCamera: (_state, _action) => {},
    storeCamera: (state, action) => {
      state.selectedCamera = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    createCamera: (_state, _action) => {},
    updateCamera: (_state, _action) => {},
    deleteCamera: (_state, _action) => {},
    removeOverviewImage: (_state, _action) => {},
    setBackendValidationError: (state, action) => {
      state.backendValidationError = action.payload;
    },
    fetchRoadTypes: () => {},
    storeRoadTypes: (state, action) => {
      state.roadTypes = action.payload;
    },
    clearData: () => initialState
  }
});

export const useFixedCamerasSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: fixedCamerasSaga });
  return { actions: slice.actions };
};

export const selectFixedCamerasState = state => state.fixedCameras;

export const { actions: fixedCamerasActions, reducer: fixedCamerasReducer } = slice;
