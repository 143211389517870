import gql from 'graphql-tag';

export const MINIMAL_TICKET_FRAG = gql`
  fragment MinimalTicketFrag on Ticket {
    id
    blockState
    carbonCopyAllowed
    dismissalState
    enforcementConfiguration
    exportState
    isWarning
    offenseCode
    paymentState
    pvNumber
    state
    suspectLastname
    type
    vehicleLicensePlateNumber
    writtenAt
    sourceSystem
    offenseLocationStreet
    offenseLocationHousenumber
    offenseLocationCity
  }
`;

export const DETAILED_TICKET_FRAG = gql`
  fragment DetailedTicketFrag on Ticket {
    attachments
    cautie
    creator {
      id
      name
    }
    partner {
      id
      name
    }
    sourceSystem
    userName
    userUsername
    partnerUsername
    recordingNumber
    ticketDecorator
    dismissalReason
    enforcementConfiguration
    errors
    explainLegalAid
    explainRightTranslator
    explainSuspectOffence
    exportConfigurations
    handcuffsUsed
    interpreterNumber
    languageOfInterrogation
    offenseCategory
    offenseClarification
    offenseFineAmountInCents
    offenseExtraFineAmountInCents
    fineSurchargeAmountInCents
    showFineSurcharge
    offenseFineStateAttorneyName
    offenseIsTowable
    offenseLocationCity
    offenseLocationHousenumber
    offenseLocationHousenumberAddition
    offenseLocationRemark
    offenseLocationRoadType
    offenseLocationStreet
    offenseLocationWithinCityLimits
    offenseLatitude
    offenseLongitude
    offenseParkingArea
    offenseType
    ownershipIndicator
    partnerId
    payedAt
    persisted
    project
    receiptNumber
    remarkOfficer
    statementOfficer
    statementSuspect
    suspectBirthCity
    suspectBirthCountry
    suspectBirthDate
    suspectFirstnames
    suspectGender
    suspectIdentificationCode
    suspectIdentificationType
    suspectIsResident
    suspectLastname
    suspectMiddlename
    suspectResidenceCity
    suspectResidenceCountry
    suspectResidenceHousenumber
    suspectResidenceHousenumberAddition
    suspectResidenceStreet
    suspectResidenceZipcode
    suspectSsn
    suspectWaivedInterrogation
    suspectZvwovp
    userId
    vehicleColor
    vehicleCountry
    vehicleLicensePlateDuplicateCode
    vehicleMake
    vehicleType
  }
`;

export const GET_TICKETS = gql`
  query GetTickets(
    $search: TicketSearch!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    tickets(search: $search, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        ...MinimalTicketFrag
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
  ${MINIMAL_TICKET_FRAG}
`;

export const GET_HIDDEN_TICKETS = gql`
  query GetHiddenTickets(
    $search: HiddenTicketSearch
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    hiddenTickets(search: $search, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        ...MinimalTicketFrag
      }
      totalCount
    }
  }
  ${MINIMAL_TICKET_FRAG}
`;

export const GET_DETAILED_TICKET = gql`
  query GetDetailedTicket($id: ID!) {
    tickets(search: { idEq: $id }, first: 1) {
      nodes {
        ...MinimalTicketFrag
        ...DetailedTicketFrag
      }
    }
  }
  ${MINIMAL_TICKET_FRAG}
  ${DETAILED_TICKET_FRAG}
`;

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      name
    }
  }
`;

export const GET_PARTNERS = gql`
  query GetPartners {
    partners {
      id
      displayName
    }
  }
`;

export const GET_CREATORS = gql`
  query GetCreators {
    creators {
      id
      displayName
    }
  }
`;

export const GET_CLARIFICATIONS = gql`
  query GetClarifications($search: ClarificationSearch!, $limit: Int, $offset: Int) {
    clarifications(search: $search, limit: $limit, offset: $offset) {
      id
      text
    }
  }
`;

export const GET_OFFICER_STATEMENTS = gql`
  query GetOfficerStatements($search: OfficerStatementSearch!) {
    officerStatements(search: $search) {
      id
      text
    }
  }
`;

export const GET_SUSPECT_STATEMENTS = gql`
  query GetSuspectStatements($search: SuspectStatementSearch!) {
    suspectStatement(search: $search) {
      id
      text
    }
  }
`;

export const GET_SOURCE_SYSTEMS = gql`
  query GetSourceSystems {
    licensedSourceSystems {
      name
      value
    }
  }
`;

export const GET_ENF_CONFIGURATIONS = gql`
  query GetEnfConfigurations {
    enforcementConfigurations {
      id
      name
    }
  }
`;

export const GET_PARKING_AREAS = gql`
  query GetParkingAreas {
    parkingAreas(limit: 150) {
      id
      name
      fixedFineInCents
    }
  }
`;

export const GET_ROAD_TYPES = gql`
  query GetRoadTypes {
    roadTypes(limit: 100) {
      id
      name
      code
    }
  }
`;

export const GET_TICKET_PERMISSIONS = gql`
  query GetTicketPermissions($id: ID, $params: JSON) {
    ticketPermission(id: $id, params: $params) {
      birthCountryChange
      block
      create
      createCarbonCopy
      destroy
      destroyExported
      dismiss
      edit
      editFineSurcharge
      editOffenseCode
      exportToPdf
      exportToWoo
      index
      new
      pay
      residenceCountryChange
      restrictedTicketType
      setCreator
      setTicketType
      show
      unblock
      undismiss
      unexport
      unpay
      update
      updateForAttachment
      attachmentsCreate
    }
  }
`;

// Only used at the ticket index screen
export const GET_TICKET_CREATE_PERMISSION = gql`
  query GetTicketPermissions($id: ID, $params: JSON) {
    ticketPermission(id: $id, params: $params) {
      create
    }
  }
`;

export const GET_TICKET_LOGS = gql`
  query GetTicketLogs($id: ID!) {
    ticketLogs(id: $id) {
      logs
    }
  }
`;
