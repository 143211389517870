import { call, put, takeLatest } from 'redux-saga/effects';
import { exemptionDetailsActions } from './slice';
import { EXEMPTION_DETAILS_QUERY } from '../ExemptionDetails/queries';

import queryRequest from '../../../../../../../../utils/queryRequest';

function* fetchDetails() {
  yield put(exemptionDetailsActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, EXEMPTION_DETAILS_QUERY, {});
    yield put(exemptionDetailsActions.storeDetails(data.exemptionDetails));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionDetailsActions.setLoading(false));
}

export function* exemptionDetailsSaga() {
  yield takeLatest(exemptionDetailsActions.fetchDetails.type, fetchDetails);
}
