import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Col, Row, Dropdown } from 'brickyard-ui';

import { addUser } from 'actions/userActions';

import BrickyardLogo from '@/assets/img/brickyard-logo.svg';
import HelpIcon from '@/assets/img/help-icon.svg';
import '../../styles/scenes/navbar.scss';
import withStoreProvider from '@/utils/withStoreProvider';
import { URLToObject } from '@/utils/URLQueryParser';

const Navbar = ({ dispatch, title, user, version, aligned }) => {
  const availableLangs = ['en', 'nl', 'en-AU', 'da', 'fr', 'sv', 'es'];
  const getLangURL = lang => {
    const hasQueryParams = !!Object.keys(URLToObject(document.location)).length;
    return `${document.location.href.replace(/locale=(&|$|\w{1,5})/, '')}${
      hasQueryParams ? '&' : '?'
    }locale=${lang}`
      .replace(/&{2,}/g, '&')
      .replace(/\?{2,}/g, '?');
  };
  const navigateTo = lang => {
    window.location.href = getLangURL(lang);
  };

  useEffect(() => {
    dispatch(addUser(user));
  }, []);

  const openHelper = () => {
    document
      .getElementById('document360-widget-iframe')
      .contentDocument.getElementById('doc360-button')
      .click();
  };

  return (
    <header className="app-header">
      <nav className={aligned ? 'aligned' : ''}>
        <a href="/" className="logo-link">
          <img alt="brickyard" src={BrickyardLogo} />
        </a>

        <h4>{title}</h4>

        {!!user && (
          <Dropdown className="right" navbar drop="down" alignRight>
            <Dropdown.Toggle variant="outline-by-primary">
              {I18n.t('header.signed_in_as', { name: user.name })}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/account?temp_ui=old">{I18n.t('header.settings')}</Dropdown.Item>
              <Dropdown.Divider />
              <span className="padded-item">{I18n.t('footer.language_select')}</span>
              <Row className="languages">
                {availableLangs.map(lang => (
                  <Col xs="6" key={lang}>
                    <a
                      onClick={() => navigateTo(lang)}
                      href="#"
                      className={lang === I18n.locale ? 'active' : ''}
                    >
                      {lang.toUpperCase()}
                    </a>
                  </Col>
                ))}
              </Row>
              <Dropdown.Divider />
              <Dropdown.Item href="/logout" data-method="delete">
                {I18n.t('header.logout')}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as="span">
                <small className="copyright">
                  Copyright &copy; {new Date().getFullYear()}{' '}
                  <a href="https://www.brickyard.eu" target="_blank" rel="noopener noreferrer">
                    Brickyard
                  </a>
                  <br />
                  {version}
                </small>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <a
          id="doc360_help_btn"
          href="#"
          className={'help-icon hide ' + (title ? '' : 'home_360doc')}
          onClick={openHelper}
        >
          <img alt="help" src={HelpIcon} />
        </a>
      </nav>
    </header>
  );
};

export default withStoreProvider(connect()(Navbar));
