import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { trackingSaga } from './saga';

const initialState = {
  devices: []
};

const slice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    fetchDevices: () => {},
    storeDevices: (state, action) => {
      state.devices = action.payload?.devices || [];
    },
    clearData: () => initialState
  }
});

export const useTrackingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: trackingSaga });

  return { actions: slice.actions };
};

export const selectTrackingState = state => state.tracking;

export const { actions: trackingActions, reducer: trackingReducer } = slice;
