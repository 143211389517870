import { call, put, takeLatest } from 'redux-saga/effects';
import { exemptionTypesActions } from './slice';
import { EXEMPTION_TYPES_QUERY, EXEMPTION_TYPE_DETAILS_QUERY } from './queries';
import queryRequest from '../../../../../../../../utils/queryRequest';
import mutationRequest from '../../../../../../../../utils/mutationRequest';
import {
  CREATE_EXEMPTION_TYPE_MUTATION,
  DELETE_EXEMPTION_TYPE_MUTATION,
  UPDATE_EXEMPTION_TYPE_MUTATION
} from './mutations';

function* fetchExemptionTypes() {
  yield put(exemptionTypesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, EXEMPTION_TYPES_QUERY, {});
    yield put(exemptionTypesActions.storeExemptionTypes(data.exemptionTypes));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionTypesActions.setLoading(false));
}

function* fetchExemptionType(action) {
  yield put(exemptionTypesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, EXEMPTION_TYPE_DETAILS_QUERY, {
      exemptionTypeId: action.payload
    });
    yield put(exemptionTypesActions.storeExemptionType(data.exemptionTypes[0]));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionTypesActions.setLoading(false));
}

function* createExemptionType(action) {
  yield put(exemptionTypesActions.setLoading(true));

  try {
    yield call(mutationRequest, CREATE_EXEMPTION_TYPE_MUTATION, action.payload);

    yield fetchExemptionTypes();
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionTypesActions.setLoading(false));
}

function* updateExemptionType(action) {
  yield put(exemptionTypesActions.setLoading(true));

  try {
    yield call(mutationRequest, UPDATE_EXEMPTION_TYPE_MUTATION, action.payload);

    yield fetchExemptionTypes();
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionTypesActions.setLoading(false));
}

function* deleteExemptionType(action) {
  yield put(exemptionTypesActions.setLoading(true));

  try {
    yield call(mutationRequest, DELETE_EXEMPTION_TYPE_MUTATION, { id: action.payload });

    yield fetchExemptionTypes();
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionTypesActions.setLoading(false));
}

export function* exemptionTypesSaga() {
  yield takeLatest(exemptionTypesActions.fetchExemptionTypes.type, fetchExemptionTypes);
  yield takeLatest(exemptionTypesActions.fetchExemptionType.type, fetchExemptionType);
  yield takeLatest(exemptionTypesActions.createExemptionType.type, createExemptionType);
  yield takeLatest(exemptionTypesActions.updateExemptionType.type, updateExemptionType);
  yield takeLatest(exemptionTypesActions.deleteExemptionType.type, deleteExemptionType);
}
