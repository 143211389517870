import { call, put, takeLatest } from 'redux-saga/effects';
import { trackingActions } from './slice';
import Api from '@/utils/Api';

function* fetchDevices() {
  try {
    const response = yield call(Api.get, '/api/v1/device_tracking');
    yield put(trackingActions.storeDevices(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* trackingSaga() {
  yield takeLatest(trackingActions.fetchDevices, fetchDevices);
}
