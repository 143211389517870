import { call, put, takeLatest } from 'redux-saga/effects';
import { eventsActions } from './slice';
import Api from '../../../../../../utils/Api';

export function* fetchEvents() {
  try {
    yield put(eventsActions.setLoading(true));
    const response = yield call(Api.get, '/cop/api/events');
    yield put(eventsActions.storeEvents(response.data.events));
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* fetchEvent(action) {
  try {
    yield put(eventsActions.setLoading(true));
    const response = yield call(Api.get, `/cop/api/events/${action.payload}`);
    yield put(eventsActions.storeEvent(response.data));
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* createEvent(action) {
  try {
    yield put(eventsActions.setLoading(true));
    const response = yield call(Api.post, '/cop/api/events', action.payload.props);
    yield put(eventsActions.storeEvent(response.data));
    yield action.payload.onSuccess();
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* updateEvent(action) {
  try {
    yield put(eventsActions.setLoading(true));
    const response = yield call(
      Api.put,
      `/cop/api/events/${action.payload.id}`,
      action.payload.props
    );
    yield put(eventsActions.storeEvent(response.data));
    yield action.payload.onSuccess();
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* deleteEvent(action) {
  try {
    yield put(eventsActions.setLoading(true));
    yield call(Api.delete, `/cop/api/events/${action.payload}`);
    yield put(eventsActions.fetchEvents());
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* fetchUseCaseCameraOptions() {
  try {
    yield put(eventsActions.setLoading(true));
    const response = yield call(Api.get, '/cop/api/events/use_case_camera_options');
    yield put(eventsActions.storeUseCaseCameraOptions(response.data));
  } catch (e) {
    yield put(eventsActions.setError(e));
  } finally {
    yield put(eventsActions.setLoading(false));
  }
}

export function* eventsSaga() {
  yield takeLatest(eventsActions.fetchEvents.type, fetchEvents);
  yield takeLatest(eventsActions.fetchEvent.type, fetchEvent);
  yield takeLatest(eventsActions.createEvent.type, createEvent);
  yield takeLatest(eventsActions.updateEvent.type, updateEvent);
  yield takeLatest(eventsActions.deleteEvent.type, deleteEvent);
  yield takeLatest(eventsActions.fetchUseCaseCameraOptions.type, fetchUseCaseCameraOptions);
}
