import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { exemptionTypesSaga } from './saga';

const initialState = {
  exemptionTypes: [],
  selectedExemptionType: null,
  loading: false
};

const slice = createSlice({
  name: 'exemptionTypes',
  initialState,
  reducers: {
    fetchExemptionTypes: () => {},
    storeExemptionTypes: (state, action) => {
      state.exemptionTypes = action.payload;
    },
    fetchExemptionType: (_state, _action) => {},
    storeExemptionType: (state, action) => {
      state.selectedExemptionType = action.payload;
    },
    createExemptionType: (_state, _action) => {},
    updateExemptionType: (_state, _action) => {},
    deleteExemptionType: (_state, _action) => {},
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearData: () => initialState
  }
});

export const useExemptionTypesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: exemptionTypesSaga });
  return { actions: slice.actions };
};

export const selectExemptionTypesState = state => state.exemptionTypes;

export const { actions: exemptionTypesActions, reducer: exemptionTypesReducer } = slice;
