import { createSlice } from '@reduxjs/toolkit';
import { locationsSaga } from './locationsSaga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

const initialState = {
  locations: [],
  selectedLocation: null,
  loading: false,
  backendValidationError: '',
  permission: 'copSettingsRead'
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    fetchLocations: () => {},
    storeLocations: (state, action) => {
      state.locations = action.payload?.locations;
      state.permission = action.payload?.permission;
    },
    fetchLocation: (_state, _action) => {},
    storeLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    createLocation: (_state, _action) => {},
    updateLocation: (_state, _action) => {},
    deleteLocation: (_state, _action) => {},
    setBackendValidationError: (state, action) => {
      state.backendValidationError = action.payload;
    },
    clearData: () => initialState
  }
});

export const useLocationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: locationsSaga });
  return { actions: slice.actions };
};

export const selectLocationsState = state => state.locations;
export const { actions: locationsActions, reducer: locationsReducer } = slice;
