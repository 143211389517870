import Api from '@/utils/Api';
import { takeLatest, put, call } from 'redux-saga/effects';
import { locationsActions } from './locationsSlice';

function createLocationsApi(action) {
  return Api.post('/cop/api/locations', action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function updateLocationsApi(action) {
  return Api.patch(`/cop/api/locations/${action.payload.id}`, action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function deleteLocationsApi(action) {
  return Api.delete(`/cop/api/locations/${action.payload}`)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function* fetchLocations() {
  yield put(locationsActions.setLoading(true));

  try {
    const result = yield call(Api.get, '/cop/api/locations');
    yield put(locationsActions.storeLocations(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(locationsActions.setLoading(false));
}

export function* fetchLocation(action) {
  yield put(locationsActions.setLoading(true));

  try {
    const result = yield call(Api.get, `/cop/api/locations/${action.payload}`);
    yield put(locationsActions.storeLocation(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(locationsActions.setLoading(false));
}

export function* createLocation(action) {
  yield put(locationsActions.setLoading(true));

  try {
    const { response, error } = yield call(createLocationsApi, action);
    if (response) {
      yield fetchLocations();
      yield action.payload.onSuccess();
    } else {
      yield put(locationsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(locationsActions.setLoading(false));
}

export function* updateLocation(action) {
  yield put(locationsActions.setLoading(true));

  try {
    const { response, error } = yield call(updateLocationsApi, action);
    if (response) {
      yield fetchLocations();
      yield action.payload.onSuccess();
    } else {
      yield put(locationsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(locationsActions.setLoading(false));
}

export function* deleteLocation(action) {
  yield put(locationsActions.setLoading(true));

  try {
    const { response, error } = yield call(deleteLocationsApi, action);
    if (response) {
      yield fetchLocations();
    } else {
      yield put(locationsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(locationsActions.setLoading(false));
}

export function* locationsSaga() {
  yield takeLatest(locationsActions.fetchLocations, fetchLocations);
  yield takeLatest(locationsActions.fetchLocation, fetchLocation);
  yield takeLatest(locationsActions.createLocation, createLocation);
  yield takeLatest(locationsActions.updateLocation, updateLocation);
  yield takeLatest(locationsActions.deleteLocation, deleteLocation);
}
