import { call, put, takeLatest } from 'redux-saga/effects';
import { useCaseListsActions } from './slice';
import Api from '../../../../../../utils/Api';
import queryRequest from '../../../../../../utils/queryRequest';
import { USE_CASES_QUERY } from '../UseCases/queries';

function createUseCaseListsApi(action) {
  return Api.post('/cop/api/use_case_lists', action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function updateUseCaseListsApi(action) {
  return Api.patch(`/cop/api/use_case_lists/${action.payload.id}`, action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function deleteUseCaseListsApi(action) {
  return Api.delete(`/cop/api/use_case_lists/${action.payload}`)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function* fetchUseCaseLists() {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const result = yield call(Api.get, '/cop/api/use_case_lists');
    yield put(useCaseListsActions.storeUseCaseLists(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

function* fetchUseCaseList(action) {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const result = yield call(Api.get, `/cop/api/use_case_lists/${action.payload}`);
    yield put(useCaseListsActions.storeUseCaseList(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

function* createUseCaseList(action) {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const { response, error } = yield call(createUseCaseListsApi, action);
    if (response) {
      yield fetchUseCaseLists();
      yield action.payload.onSuccess();
    } else {
      yield put(useCaseListsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

function* updateUseCaseList(action) {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const { response, error } = yield call(updateUseCaseListsApi, action);
    if (response) {
      yield fetchUseCaseLists();
      yield action.payload.onSuccess();
    } else {
      yield put(useCaseListsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

function* deleteUseCaseList(action) {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const { response, error } = yield call(deleteUseCaseListsApi, action);
    if (response) {
      yield fetchUseCaseLists();
      yield action.payload.onSuccess();
    } else {
      yield put(useCaseListsActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

function* fetchAvailableUseCases() {
  yield put(useCaseListsActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, USE_CASES_QUERY, {});
    yield put(useCaseListsActions.storeAvailableUseCases(data.useCases));
  } catch (e) {
    console.error(e);
  }

  yield put(useCaseListsActions.setLoading(false));
}

export function* usecaseListsSaga() {
  yield takeLatest(useCaseListsActions.fetchUseCaseLists, fetchUseCaseLists);
  yield takeLatest(useCaseListsActions.fetchUseCaseList, fetchUseCaseList);
  yield takeLatest(useCaseListsActions.createUseCaseList, createUseCaseList);
  yield takeLatest(useCaseListsActions.updateUseCaseList, updateUseCaseList);
  yield takeLatest(useCaseListsActions.deleteUseCaseList, deleteUseCaseList);
  yield takeLatest(useCaseListsActions.fetchAvailableUseCases, fetchAvailableUseCases);
}
