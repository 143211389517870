import {
  ADD_OBSERVATION_RELATION_METADATA,
  ADD_OBSERVATION_ACTION_METADATA,
  LOAD_OBSERVATION_METADATA,
  REMOVE_OBSERVATION_RELATION_METADATA,
  REMOVE_OBSERVATION_METADATA,
  CLEAN_METADATA,
  SET_CURRENT_OBSERVATION,
  SET_OBSERVATIONS_FILTERS,
  SET_PREVIOUS_BOUNDS,
  REMOVE_OBSERVATION_ALL_RELATIONS_METADATA
} from '../actions/actionTypes';
import { omit } from 'ramda';
import _ from 'lodash';

let relations;
let newState;
let newObsState;

const observationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_OBSERVATION_RELATION_METADATA:
      if (
        !state[action.relation.mainObservationId] ||
        !state[action.relation.mainObservationId].relations
      ) {
        return {
          ...state,
          [action.relation.mainObservationId]: {
            relations: new Set([action.relation.relatedId])
          }
        };
      }
      return {
        ...state,
        [action.relation.mainObservationId]: {
          relations: new Set([
            ...state[action.relation.mainObservationId].relations,
            action.relation.relatedId
          ])
        }
      };
    case REMOVE_OBSERVATION_RELATION_METADATA:
      relations = new Set(state[action.relation.mainObservationId].relations);
      relations.delete(action.relation.relatedId);
      return {
        ...state,
        [action.relation.mainObservationId]: {
          ...state[action.relation.mainObservationId],
          relations
        }
      };
    case REMOVE_OBSERVATION_ALL_RELATIONS_METADATA:
      newObsState = { ...state[action.observationId] };
      delete newObsState['relations'];
      return {
        ...state,
        [action.observationId]: newObsState
      };
    case ADD_OBSERVATION_ACTION_METADATA:
      return {
        ...state,
        [action.payload.observationId]: {
          ...state[action.payload.observationId],
          action: action.payload.action,
          validatedBy: action.payload.validatedBy,
          dismissalReason: action.payload.dismissalReason
        }
      };
    case LOAD_OBSERVATION_METADATA:
      return {
        ...state,
        ...action.metadataObj
      };
    case REMOVE_OBSERVATION_METADATA:
      newState = { ...state };
      delete newState[action.observationId];
      return { ...newState };
    case CLEAN_METADATA:
      return _.pick({ ...state }, ['filters', 'current']);
    case SET_CURRENT_OBSERVATION:
      return {
        ...state,
        current: action.payload
      };
    case SET_OBSERVATIONS_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case SET_PREVIOUS_BOUNDS:
      if (action?.payload) {
        return {
          ...state,
          previousBounds: {
            bounds: action.payload.bounds,
            zoom: action.payload.zoom
          }
        };
      } else {
        return omit(['previousBounds'], state);
      }
  }

  return state;
};

export default observationsReducer;
