import { createStore, applyMiddleware, compose } from 'redux';
import { createInjectorsEnhancer } from 'redux-injectors';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware({});
const { run: runSaga } = sagaMiddleware;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunkMiddleware, sagaMiddleware];

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  createInjectorsEnhancer({
    createReducer: () => rootReducer,
    runSaga
  })
);

export default createStore(rootReducer, enhancer);
