import gql from 'graphql-tag';

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
      id
      name
      permissions
    }
  }
`;

export const GET_VEHICLE_MAKES = gql`
  query GetVehicleMakes {
    vehicleMakes(limit: 100) {
      id
      name
    }
  }
`;

export const GET_VEHICLE_COLORS = gql`
  query GetVehicleColors {
    vehicleColors(limit: 100) {
      id
      name
    }
  }
`;

export const GET_VEHICLE_TYPES = gql`
  query GetVehicleTypes($needRdwConst: Boolean) {
    vehicleTypes(search: { needRdwConst: $needRdwConst }) {
      id
      name
      rawName
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries(limit: 195) {
      id
      code
    }
  }
`;

export const GET_WHITELISTS = gql`
  query GetWhitelists {
    whitelists {
      id
      name
      description
    }
  }
`;

export const GET_OFFENSES = gql`
  query GetOffenses($code: String, $type: String) {
    offenses(limit: 195, search: { code: $code, type: $type }) {
      id
      offenseCode
      fineAmountInCents
      personalInformationValidation
      vehicleInformationValidation
      fmtOffenseAmount
      text
      ticketType
      offenseCategoryGroup
      offenseCategoryDescription
      offenseCategories {
        id
        description
        typeCategory
        categoryGroup
      }
    }
  }
`;

export const GET_AREAS = gql`
  query GetAreas($search: AreaSearch, $limit: Int) {
    areas(search: $search, limit: $limit) {
      id
      mapParameters
      name
      dismissalReasonRequired
      organisation {
        id
        country {
          name
        }
      }
      cameras {
        id
        name
        latitude
        longitude
        active
        rotation
      }
    }
  }
`;

export const GET_USE_CASES = gql`
  query GetUseCases($copPermissionBasedScope: Boolean = false, $limit: Int) {
    useCases(search: { copPermissionBasedScope: $copPermissionBasedScope }, limit: $limit) {
      id
      name
      dismissalReasonRequired
      warningEnabled
      exemptionEnabled
      organisation {
        id
        linkRelatedObservations
        country {
          name
        }
      }
      cameras(search: { copPermissionBasedScope: $copPermissionBasedScope }) {
        id
        type
        name
        latitude
        longitude
        location
        active
        rotation
      }
      locations {
        id
        name
      }
    }
  }
`;

export const GET_SUSPECT_LISTS = gql`
  query getSuspectLists {
    suspectLists {
      id
      name
      useCases {
        id
        name
      }
    }
  }
`;

export const GET_PERMIT_TYPES = gql`
  query GetPermitTypes {
    permitTypes {
      id
      nameEn
      nameNl
      temporary
    }
  }
`;

export const GET_PARKING_RIGHT_PROVIDERS = gql`
  query GetParkingRightProviders {
    parkingRightProviders {
      name
    }
  }
`;

export const UPDATE_USE_CASE_RESULT = gql`
  mutation CopUpdateUseCaseResult(
    $id: ID!
    $vehicleLicensePlateNumber: String
    $countryCode: String
    $vehicleType: String
    $offenseCode: String
    $offenseLocationStreet: String
    $vehicleMake: String
    $vehicleColor: String
    $duplicateCode: Int
    $updateAll: Boolean
  ) {
    copUpdateUseCaseResult(
      id: $id
      vehicleLicensePlateNumber: $vehicleLicensePlateNumber
      countryCode: $countryCode
      vehicleType: $vehicleType
      vehicleMake: $vehicleMake
      vehicleColor: $vehicleColor
      offenseCode: $offenseCode
      offenseLocationStreet: $offenseLocationStreet
      duplicateCode: $duplicateCode
      updateAll: $updateAll
    ) {
      id
      vehicleLicensePlateNumber
      countryCode
      vehicleType
      vehicleMake
      vehicleColor
      offenseCode
      offenseLocationStreet
      duplicateCode
    }
  }
`;
