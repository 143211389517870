import { gql } from '@apollo/client';

const EXEMPTIONS_LIST_QUERY = gql`
  query GetExemptionsList($search: ExemptionSearch) {
    exemptionsList(search: $search) {
      id
      scope
      type
      result
      useCaseName
      licensePlate
      exemptedByName
      requestedByName
      requestedOn
      requestedBy {
        id
        name
      }
      exemptedOn
      exemptedBy {
        id
        name
      }
      copExemptionType {
        id
        name
        global
      }
      copUseCase {
        id
        name
      }
    }
  }
`;

export { EXEMPTIONS_LIST_QUERY };
