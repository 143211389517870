import Client from './Client';

function checkResponse(response) {
  if (response.errors) {
    response.errors.map(error => {
      throw new Error(error.message);
    });
  }

  return response;
}

Client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only'
  }
};

export default function queryRequest(query, variables) {
  return Client.query({ query, variables }).then(checkResponse);
}
