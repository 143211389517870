import { gql } from '@apollo/client';

const USE_CASES_QUERY = gql`
  query GetUseCases($search: UseCaseSearch) {
    useCases(search: $search) {
      id
      name
      organisation {
        id
      }
    }
  }
`;

const USE_CASE_DETAILS_QUERY = gql`
  query GetUseCaseDetails($useCaseId: [ID!]) {
    useCases(search: { id: $useCaseId }) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      allowValidationWithMissingVehicleType
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      usesByMobile
      imageUrl
      organisation {
        id
      }
      cameras(search: { copPermissionBasedScope: false }) {
        id
        active
        name
        type
        meta(useCaseId: $useCaseId)
        zoneId(useCaseId: $useCaseId)
        useCaseCameraEnabled(useCaseId: $useCaseId)
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
      printTemplates {
        id
        name
        body
        kind
      }
      shapeFiles {
        id
        versionName
        status
        processLog
      }
      copShapeFileId
    }
  }
`;

const USE_CASE_ZONES_QUERY = gql`
  query GetUseCaseZones($search: UseCaseSearch) {
    useCases(search: $search) {
      id
      zones {
        id
        geom
      }
    }
  }
`;

const CAMERAS_QUERY = gql`
  query GetCameras($search: CopCameraSearch, $useCaseId: [ID!]) {
    copCameras(search: $search) {
      id
      name
      type
      meta(useCaseId: $useCaseId)
      zoneId(useCaseId: $useCaseId)
      useCaseCameraEnabled(useCaseId: $useCaseId)
    }
  }
`;

const PIPELINE_QUERY = gql`
  query GetPipeline($useCaseId: ID!, $pipelineId: ID!) {
    useCases(search: { id: [$useCaseId] }) {
      id
      pipelines(search: { id: [$pipelineId] }) {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const PROCESSOR_QUERY = gql`
  query GetProcessor($useCaseId: ID!, $pipelineId: ID!, $processorId: ID!) {
    useCases(search: { id: [$useCaseId] }) {
      id
      pipelines(search: { id: [$pipelineId] }) {
        id
        pipelineProcessors(search: { id: [$processorId] }) {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

export {
  USE_CASES_QUERY,
  USE_CASE_DETAILS_QUERY,
  USE_CASE_ZONES_QUERY,
  CAMERAS_QUERY,
  PIPELINE_QUERY,
  PROCESSOR_QUERY
};
