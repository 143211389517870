import { gql } from '@apollo/client';

const DELETE_EXEMPTION_MUTATION = gql`
  mutation DeleteExemption($id: ID!) {
    exemptionDelete(id: $id) {
      id
    }
  }
`;

const CREATE_EXEMPTION_MUTATION = gql`
  mutation CreateExemption(
    $licensePlate: String!
    $useCaseId: ID
    $exemptionTypeId: ID!
    $result: String
  ) {
    exemptionCreate(
      licensePlate: $licensePlate
      copUseCaseId: $useCaseId
      copExemptionTypeId: $exemptionTypeId
      result: $result
    ) {
      id
      licensePlate
      result
      exemptedOn
      requestedOn
      copUseCase {
        id
        name
      }
      copExemptionType {
        id
        name
        global
      }
      requestedBy {
        id
        name
      }
      exemptedBy {
        id
        name
      }
    }
  }
`;

export { DELETE_EXEMPTION_MUTATION, CREATE_EXEMPTION_MUTATION };
