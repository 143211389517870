import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { exemptionsListSaga } from './saga';

const initialState = {
  exemptionsList: [],
  selectedExemption: null,
  loading: false,
  exemptionTypes: [],
  useCases: [],
  errors: []
};

const slice = createSlice({
  name: 'exemptionsList',
  initialState,
  reducers: {
    fetchExemptionsList: () => {},
    storeExemptionsList: (state, action) => {
      state.exemptionsList = action.payload;
    },
    storeExemption: (state, action) => {
      state.selectedExemption = action.payload;
    },
    deleteExemption: (_state, _action) => {},
    fetchExemptionTypes: () => {},
    storeExemptionTypes: (state, action) => {
      state.exemptionTypes = action.payload;
    },
    fetchUseCases: () => {},
    storeUseCases: (state, action) => {
      state.useCases = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearData: () => initialState
  }
});

export const useExemptionsListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: exemptionsListSaga });
  return { actions: slice.actions };
};

export const selectExemptionsListState = state => state.exemptionsList;

export const { actions: exemptionsListActions, reducer: exemptionsListReducer } = slice;
