import { call, put, takeLatest } from 'redux-saga/effects';
import mutationRequest from '../../../../../../utils/mutationRequest';
import queryRequest from '../../../../../../utils/queryRequest';
import { GET_EXEMPTION_TYPES } from '../../../../../queries/cop/exemptions/exemption_types';
import { PROCESS_EXEMPTION_REQUEST } from './mutations';
import { GET_EXEMPTION_REQUEST, GET_EXEMPTION_REQUESTS } from './queries';
import { exemptionRequestsActions } from './slice';

function* fetchExemptionRequests() {
  yield put(exemptionRequestsActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_EXEMPTION_REQUESTS, {});
    yield put(exemptionRequestsActions.storeExemptionRequests(data.exemptionRequests));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionRequestsActions.setLoading(false));
}

function* fetchExemptionRequest(action) {
  yield put(exemptionRequestsActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_EXEMPTION_REQUEST, {
      search: { id: action.payload }
    });
    yield put(exemptionRequestsActions.storeExemptionRequest(data.exemptionRequests[0]));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionRequestsActions.setLoading(false));
}

function* mutateExemptionRequest(action) {
  yield put(exemptionRequestsActions.setLoading(true));

  try {
    yield call(mutationRequest, PROCESS_EXEMPTION_REQUEST, action.payload);
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionRequestsActions.storeExemptionRequest(null));
  yield put(exemptionRequestsActions.storeExemptionRequests([]));
  yield fetchExemptionRequests();
  yield put(exemptionRequestsActions.setLoading(false));
}

function* fetchExemptionTypes() {
  yield put(exemptionRequestsActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_EXEMPTION_TYPES, { nonCopSettingsScope: true });
    yield put(exemptionRequestsActions.storeExemptionTypes(data.exemptionTypes));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionRequestsActions.setLoading(false));
}

export function* exemptionRequestsSaga() {
  yield takeLatest(exemptionRequestsActions.fetchExemptionRequests.type, fetchExemptionRequests);
  yield takeLatest(exemptionRequestsActions.fetchExemptionRequest.type, fetchExemptionRequest);
  yield takeLatest(exemptionRequestsActions.mutateExemptionRequest.type, mutateExemptionRequest);
  yield takeLatest(exemptionRequestsActions.fetchExemptionTypes.type, fetchExemptionTypes);
}
