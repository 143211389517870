import { FETCH_PERMISSIONS_SUCCESS } from '../actions/actionTypes';

const initialState = {
  stored: false,
  ticketPermissions: {
    birthCountryChange: false,
    block: false,
    create: false,
    createCarbonCopy: false,
    destroy: false,
    destroyExported: false,
    dismiss: false,
    edit: false,
    editFineSurcharge: false,
    editOffenseCode: false,
    exportToPdf: false,
    exportToWoo: false,
    index: false,
    new: false,
    pay: false,
    residenceCountryChange: false,
    restrictedTicketType: false,
    setCreator: false,
    setTicketType: false,
    show: false,
    unblock: false,
    undismiss: false,
    unexport: false,
    unpay: false,
    update: false,
    updateForAttachment: false,
    license: false,
    attachmentsCreate: false
  }
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        stored: true,
        ticketPermissions: { ...state.ticketPermissions, ...action.payload }
      };
    default:
      return state;
  }
};

export default permissionsReducer;
