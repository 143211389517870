import { createSlice } from '@reduxjs/toolkit';
import { models } from 'powerbi-client';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { powerBIPageSaga } from './saga';

const initialState = {
  loading: false,
  error: null,
  embedConfig: {
    type: 'report',
    tokenType: models.TokenType.Embed,
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined
  }
};

const slice = createSlice({
  name: 'powerBIPage',
  initialState,
  reducers: {
    fetchReport: (_state, _action) => {},
    storeReport: (state, action) => {
      state.embedConfig = {
        ...state.embedConfig,
        id: action.payload.report_details.id,
        embedUrl: action.payload.report_details.embed_url,
        accessToken: action.payload.embed_token
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearData: () => initialState
  }
});

export const usePowerBIPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: powerBIPageSaga });
  return { actions: slice.actions };
};

export const selectPowerBIPageState = state => state.powerBIPage;

export const { actions: powerBIPageActions, reducer: powerBIPageReducer } = slice;
