import { call, put, takeLatest } from 'redux-saga/effects';
import { powerBIPageActions } from './slice';
import Api from '../../../utils/Api';

export function* fetchData(action) {
  yield put(powerBIPageActions.setError(null));
  yield put(powerBIPageActions.setLoading(true));

  try {
    const result = yield call(Api.get, `/reporting/power_bi/embed_details?page=${action.payload}`, {
      timeout: 30000
    });
    yield put(powerBIPageActions.storeReport(result.data));
    yield put(powerBIPageActions.setLoading(false));
  } catch (e) {
    yield put(powerBIPageActions.setError(e));
  }
}

export function* powerBIPageSaga() {
  yield takeLatest(powerBIPageActions.fetchReport.type, fetchData);
}
