import { ADD_USER } from '../actions/actionTypes';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER:
      return { ...action.user };
  }

  return state;
};

export default userReducer;
