import { createSlice } from '@reduxjs/toolkit';
import { exemptionDetailsSaga } from './saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

const initialState = {
  details: null,
  loading: false
};

const slice = createSlice({
  name: 'exemptionDetails',
  initialState,
  reducers: {
    fetchDetails: () => {},
    storeDetails: (state, action) => {
      state.details = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearData: () => initialState
  }
});

export const useExemptionDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: exemptionDetailsSaga });
  return { actions: slice.actions };
};

export const selectExemptionDetailsState = state => state.exemptionDetails;

export const { actions: exemptionDetailsActions, reducer: exemptionDetailsReducer } = slice;
