import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { exemptionRequestsSaga } from './saga';

const initialState = {
  exemptionRequests: [],
  selectedExemptionRequest: null,
  loading: false,
  exemptionTypes: []
};

const slice = createSlice({
  name: 'exemptionRequests',
  initialState,
  reducers: {
    fetchExemptionRequests: () => {},
    storeExemptionRequests: (state, action) => {
      state.exemptionRequests = action.payload;
    },
    fetchExemptionRequest: (_state, _action) => {},
    storeExemptionRequest: (state, action) => {
      state.selectedExemptionRequest = action.payload;
    },
    mutateExemptionRequest: (_state, _action) => {},
    fetchExemptionTypes: () => {},
    storeExemptionTypes: (state, action) => {
      state.exemptionTypes = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearData: () => initialState
  }
});

export const useExemptionRequestsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: exemptionRequestsSaga });

  return { actions: slice.actions };
};

export const selectExemptionRequestsState = state => state.exemptionRequests;

export const { actions: exemptionRequestsActions, reducer: exemptionRequestsReducer } = slice;
