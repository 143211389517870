import Client from './Client';

function checkResponse(response) {
  if (response.errors) {
    response.errors.map(error => {
      throw new Error(error.message);
    });
  }

  return response;
}

export default function mutationRequest(mutation, variables) {
  return Client.mutate({ mutation, variables }).then(checkResponse);
}
