import { gql } from '@apollo/client';

const EXEMPTION_DETAILS_QUERY = gql`
  query GetExemptionDetails {
    exemptionDetails {
      id
      notificationEmail
    }
  }
`;

export { EXEMPTION_DETAILS_QUERY };
