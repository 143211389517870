import { gql } from '@apollo/client';

const CREATE_EXEMPTION_TYPE_MUTATION = gql`
  mutation CreateExemptionType($name: String, $global: Boolean) {
    exemptionTypeCreate(name: $name, global: $global) {
      id
      name
      global
    }
  }
`;

const UPDATE_EXEMPTION_TYPE_MUTATION = gql`
  mutation UpdateExemptionType($id: ID!, $name: String, $global: Boolean) {
    exemptionTypeUpdate(id: $id, name: $name, global: $global) {
      id
      name
      global
    }
  }
`;

const DELETE_EXEMPTION_TYPE_MUTATION = gql`
  mutation DeleteExemptionType($id: ID!) {
    exemptionTypeDelete(id: $id) {
      id
    }
  }
`;

export {
  CREATE_EXEMPTION_TYPE_MUTATION,
  UPDATE_EXEMPTION_TYPE_MUTATION,
  DELETE_EXEMPTION_TYPE_MUTATION
};
