import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { mobileCamerasSaga } from './saga';

const initialState = {
  cameras: [],
  selectedCamera: null,
  loading: false,
  backendValidationError: '',
  permission: 'copSettingsRead'
};

const slice = createSlice({
  name: 'mobileCameras',
  initialState,
  reducers: {
    fetchCameras: () => {},
    storeCameras: (state, action) => {
      state.cameras = action.payload?.cameras;
      state.permission = action.payload?.permission;
    },
    fetchCamera: (_state, _action) => {},
    storeCamera: (state, action) => {
      state.selectedCamera = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    createCamera: (_state, _action) => {},
    updateCamera: (_state, _action) => {},
    deleteCamera: (_state, _action) => {},
    setBackendValidationError: (state, action) => {
      state.backendValidationError = action.payload;
    },
    clearData: () => initialState
  }
});

export const useMobileCamerasSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mobileCamerasSaga });
  return { actions: slice.actions };
};

export const selectMobileCamerasState = state => state.mobileCameras;

export const { actions: mobileCamerasActions, reducer: mobileCamerasReducer } = slice;
