import { call, put, takeLatest } from 'redux-saga/effects';
import { exemptionsListActions } from './slice';
import { EXEMPTIONS_LIST_QUERY } from './queries';
import { DELETE_EXEMPTION_MUTATION } from './mutations';

import queryRequest from '../../../../../../../../utils/queryRequest';
import mutationRequest from '../../../../../../../../utils/mutationRequest';
import { GET_EXEMPTION_TYPES } from '../../../../../../../queries/cop/exemptions/exemption_types';
import { GET_USE_CASES } from '../../../../../../../queries/SharedQueries';

function* fetchExemptionsList() {
  yield put(exemptionsListActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, EXEMPTIONS_LIST_QUERY, {});

    yield put(exemptionsListActions.storeExemptionsList(data.exemptionsList));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionsListActions.setLoading(false));
}

function* deleteExemption(action) {
  yield put(exemptionsListActions.setLoading(true));

  try {
    yield call(mutationRequest, DELETE_EXEMPTION_MUTATION, { id: action.payload });

    yield fetchExemptionsList();
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionsListActions.setLoading(false));
}

function* fetchExemptionTypes() {
  yield put(exemptionsListActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_EXEMPTION_TYPES, {});
    yield put(exemptionsListActions.storeExemptionTypes(data.exemptionTypes));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionsListActions.setLoading(false));
}

function* fetchUseCases() {
  yield put(exemptionsListActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_USE_CASES, {});
    yield put(exemptionsListActions.storeUseCases(data.useCases));
  } catch (e) {
    console.error(e);
  }

  yield put(exemptionsListActions.setLoading(false));
}

export function* exemptionsListSaga() {
  yield takeLatest(exemptionsListActions.fetchExemptionsList.type, fetchExemptionsList);
  yield takeLatest(exemptionsListActions.deleteExemption.type, deleteExemption);
  yield takeLatest(exemptionsListActions.fetchExemptionTypes.type, fetchExemptionTypes);
  yield takeLatest(exemptionsListActions.fetchUseCases.type, fetchUseCases);
}
