/**
 * This function parses an object (without nested values) to a url query string.
 *
 * @param {Object} obj Object to be parsed e.g. { attr1: value1, attr2: value2 }
 * @return {String} Query string as on ?attr1=value1&attr2=value2
 */
export const ObjectToQuery = obj => {
  const query = Object.keys(obj)
    .filter(key => obj[key] !== null && obj[key] !== '')
    .map(key => `${key}=${obj[key]}`)
    .join('&');

  return `?${encodeURI(query)}`;
};

/**
 * This function parses an location's search, as on window.location, to an object.
 * The locale key is skipped because it's temporary used by the backend to set the language cookie,
 * so it doesn't make sense to be part of the search or any other binding, since it's a user preference.
 *
 * @param {Location} location Object representing a location, only mandatory attribute is search
 * @return {Object} Return the object representing the query string on the location
 */
export const URLToObject = location => {
  const { search } = location;
  const searchParams = new URLSearchParams(decodeURI(search));
  const obj = {};
  for (let [key, value] of searchParams.entries()) {
    if (key !== 'locale' && key !== 'ui') {
      let parsedVal = value;
      if (value === 'false' || value === 'true') {
        parsedVal = JSON.parse(value);
      }
      obj[key] = parsedVal;
    }
  }

  return obj;
};

/**
 * This function transforms params from camelCase into underscore_case
 *
 * @param {Object} params Object with keys as camelcase
 * @return {Object} Return the object with keys as underscore
 */
export const CamelCaseToUnderscore = params => {
  return Object.keys(params).reduce((acc, key) => {
    acc[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = params[key];
    return acc;
  }, {});
};
