import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { usecaseListsSaga } from './saga';

const initialState = {
  useCaseLists: [],
  selectedUseCaseList: null,
  availableUseCases: [],
  loading: false,
  backendValidationError: '',
  permission: 'copSettingsRead'
};

const slice = createSlice({
  name: 'useCaseLists',
  initialState,
  reducers: {
    fetchUseCaseLists: () => {},
    storeUseCaseLists: (state, action) => {
      state.useCaseLists = action.payload?.use_case_lists;
      state.permission = action.payload?.permission;
    },
    fetchUseCaseList: (_state, _action) => {},
    storeUseCaseList: (state, action) => {
      state.selectedUseCaseList = action.payload;
    },
    fetchAvailableUseCases: (_state, _action) => {},
    storeAvailableUseCases: (state, action) => {
      state.availableUseCases = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    createUseCaseList: (_state, _action) => {},
    updateUseCaseList: (_state, _action) => {},
    deleteUseCaseList: (_state, _action) => {},
    setBackendValidationError: (state, action) => {
      state.backendValidationError = action.payload;
    },
    clearData: () => initialState
  }
});

export const useUseCaseListsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: usecaseListsSaga });
  return { actions: slice.actions };
};

export const selectUseCaseListsState = state => state.useCaseLists;

export const { actions: useCaseListsActions, reducer: useCaseListsReducer } = slice;
