import { takeLatest, put, call } from 'redux-saga/effects';
import { mobileCamerasActions } from './slice';
import Api from '@/utils/Api';

function createCamerasApi(action) {
  return Api.post('/cop/api/cameras', action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function updateCamerasApi(action) {
  return Api.patch(`/cop/api/cameras/${action.payload.id}`, action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function deleteCamerasApi(action) {
  return Api.delete(`/cop/api/cameras/${action.payload}`)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function* fetchCameras() {
  yield put(mobileCamerasActions.setLoading(true));

  try {
    const result = yield call(Api.get, '/cop/api/cameras', {
      params: { type: 'Cop::MobileCamera' }
    });
    yield put(mobileCamerasActions.storeCameras(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(mobileCamerasActions.setLoading(false));
}

export function* fetchCamera(action) {
  yield put(mobileCamerasActions.setLoading(true));

  try {
    const result = yield call(Api.get, `/cop/api/cameras/${action.payload}`);
    yield put(mobileCamerasActions.storeCamera(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(mobileCamerasActions.setLoading(false));
}

export function* createCamera(action) {
  yield put(mobileCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(createCamerasApi, action);
    if (response) {
      yield fetchCameras();
      yield action.payload.onSuccess();
    } else {
      yield put(mobileCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(mobileCamerasActions.setLoading(false));
}

export function* updateCamera(action) {
  yield put(mobileCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(updateCamerasApi, action);
    if (response) {
      yield fetchCameras();
      yield action.payload.onSuccess();
    } else {
      yield put(mobileCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(mobileCamerasActions.setLoading(false));
}

export function* deleteCamera(action) {
  yield put(mobileCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(deleteCamerasApi, action);
    if (response) {
      yield fetchCameras();
    } else {
      yield put(mobileCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(mobileCamerasActions.setLoading(false));
}

export function* mobileCamerasSaga() {
  yield takeLatest(mobileCamerasActions.fetchCameras, fetchCameras);
  yield takeLatest(mobileCamerasActions.fetchCamera, fetchCamera);
  yield takeLatest(mobileCamerasActions.createCamera, createCamera);
  yield takeLatest(mobileCamerasActions.updateCamera, updateCamera);
  yield takeLatest(mobileCamerasActions.deleteCamera, deleteCamera);
}
