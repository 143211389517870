import axios from 'axios';
import * as qs from 'qs';

const authenticityToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  if (token && token instanceof window.HTMLMetaElement) {
    return token.content;
  }
  return null;
};

const authenticityHeaders = () => ({
  'X-CSRF-Token': authenticityToken(),
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json'
});

const Api = axios.create({
  timeout: 5000,
  headers: authenticityHeaders(),
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  }
});

export default Api;
