import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { eventsSaga } from './saga';

const initialState = {
  events: [],
  selectedEvent: null,
  useCaseCameraOptions: [],
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    fetchEvents: () => {},
    storeEvents: (state, action) => {
      state.events = action.payload;
    },
    fetchEvent: (_state, _action) => {},
    storeEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    fetchUseCaseCameraOptions: () => {},
    storeUseCaseCameraOptions: (state, action) => {
      state.useCaseCameraOptions = action.payload;
    },
    createEvent: (_state, _action) => {},
    updateEvent: (_state, _action) => {},
    deleteEvent: (_state, _action) => {},
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearData: () => initialState
  }
});

export const useEventsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: eventsSaga });
  return { actions: slice.actions };
};

export const selectEventsState = state => state.events;

export const { actions: eventsActions, reducer: eventsReducer } = slice;
