import { takeLatest, put, call } from 'redux-saga/effects';
import { fixedCamerasActions } from './slice';
import Api from '@/utils/Api';
import queryRequest from '../../../../../../utils/queryRequest';
import { GET_ROAD_TYPES } from '../../../../../queries/tickets';

function createCamerasApi(action) {
  return Api.post('/cop/api/cameras', action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function updateCamerasApi(action) {
  return Api.patch(`/cop/api/cameras/${action.payload.id}`, action.payload)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function updateCamerasOverviewImageApi(action) {
  if (!action.payload.props.overview_image) {
    return;
  }
  const formData = new FormData();
  formData.append('id', action.payload.id);
  formData.append('file', action.payload.props.overview_image);

  return Api.patch(`/cop/api/cameras/${action.payload.id}/overview-image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function deleteCamerasApi(action) {
  return Api.delete(`/cop/api/cameras/${action.payload}`)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function removeOverviewImageApi(action) {
  return Api.delete(`/cop/api/cameras/${action.payload.id}/overview-image`)
    .then(response => ({ response }))
    .catch(error => error.response.data);
}

function* fetchCameras() {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const result = yield call(Api.get, '/cop/api/cameras', {
      params: { type: 'Cop::FixedCamera' }
    });
    yield put(fixedCamerasActions.storeCameras(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* fetchCamera(action) {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const result = yield call(Api.get, `/cop/api/cameras/${action.payload}`);
    yield put(fixedCamerasActions.storeCamera(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* createCamera(action) {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(createCamerasApi, action);
    if (response) {
      action.payload.id = response.data.id;
      yield call(updateCamerasOverviewImageApi, action);
      yield fetchCameras();
      yield action.payload.onSuccess();
    } else {
      yield put(fixedCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* updateCamera(action) {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(updateCamerasApi, action);
    if (response) {
      yield call(updateCamerasOverviewImageApi, action);
      yield fetchCameras();
      yield action.payload.onSuccess();
    } else {
      yield put(fixedCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* deleteCamera(action) {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(deleteCamerasApi, action);
    if (response) {
      yield fetchCameras();
    } else {
      yield put(fixedCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* removeOverviewImage(action) {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const { response, error } = yield call(removeOverviewImageApi, action);
    if (!response) {
      yield put(fixedCamerasActions.setBackendValidationError(error));
    }
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

function* fetchRoadTypes() {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_ROAD_TYPES, {});
    yield put(fixedCamerasActions.storeRoadTypes(data?.roadTypes));
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

function* fetchLocations() {
  yield put(fixedCamerasActions.setLoading(true));

  try {
    const result = yield call(Api.get, '/cop/api/locations');
    yield put(fixedCamerasActions.storeLocations(result.data));
  } catch (e) {
    console.error(e);
  }

  yield put(fixedCamerasActions.setLoading(false));
}

export function* fixedCamerasSaga() {
  yield takeLatest(fixedCamerasActions.fetchCameras, fetchCameras);
  yield takeLatest(fixedCamerasActions.fetchCamera, fetchCamera);
  yield takeLatest(fixedCamerasActions.createCamera, createCamera);
  yield takeLatest(fixedCamerasActions.updateCamera, updateCamera);
  yield takeLatest(fixedCamerasActions.deleteCamera, deleteCamera);
  yield takeLatest(fixedCamerasActions.removeOverviewImage, removeOverviewImage);
  yield takeLatest(fixedCamerasActions.fetchRoadTypes, fetchRoadTypes);
  yield takeLatest(fixedCamerasActions.fetchLocations, fetchLocations);
}
